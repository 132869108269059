import type { BlockStateDefinition } from '@unifyapps/carbon/no-code/components/BlockDefinition';
import type { BlockType, ComponentTypeUnionType } from '@unifyapps/defs/types/block';
import type { PDFViewerComponentType } from '@unifyapps/defs/types/blocks';
import _pick from 'lodash/pick';
import { lazy } from 'react';

const PDFViewer = lazy(
  () =>
    import(
      /* webpackChunkName: "pdf-viewer-block" */
      './PDFViewer'
    ),
);

class PDFViewerBlockStateDefinition implements BlockStateDefinition {
  get type(): ComponentTypeUnionType {
    return 'PDFViewer';
  }

  getEventTargetIds() {
    return [];
  }

  getBlockControlMethods() {
    return [];
  }

  get initialStateGetter() {
    return (block: BlockType) => ({
      id: block.id,
      ..._pick(block.component as PDFViewerComponentType, [
        'content',
        'appearance',
        'componentType',
      ]),
    });
  }

  getComponent() {
    return PDFViewer;
  }
}

export default PDFViewerBlockStateDefinition;
