import type { BlockStateDefinition } from '@unifyapps/carbon/no-code/components/BlockDefinition';
import type {
  ComponentTypeUnionType,
  BlockType,
  BlockStateUnionType,
} from '@unifyapps/defs/types/block';
import type { DrawerComponentType } from '@unifyapps/defs/blocks/Drawer/types';
import _pick from 'lodash/pick';
import type { Action } from '@unifyapps/defs/types/action';
import { lazy } from 'react';

const Drawer = lazy(
  () =>
    import(
      /* webpackChunkName: "drawer-block" */
      './Drawer'
    ),
);

class DrawerBlockStateDefinition implements BlockStateDefinition {
  getComponent() {
    return Drawer;
  }

  get type(): ComponentTypeUnionType {
    return 'Drawer';
  }

  useBlockRef?: boolean | undefined = true;

  getEventTargetIds() {
    return [];
  }

  getBlockControlMethods() {
    return [
      {
        id: 'showDrawer',
        label: 'Show',
        method: (_blockState: BlockStateUnionType, _: Action, ref?: { open: () => void }) => {
          ref?.open();
          return Promise.resolve({
            ..._blockState,
            state: {
              open: true,
            },
          } as BlockStateUnionType);
        },
      },
      {
        id: 'hideDrawer',
        label: 'Hide',
        method: (_blockState: BlockStateUnionType, _: Action, ref?: { close: () => void }) => {
          ref?.close();
          return Promise.resolve({
            ..._blockState,
            state: {
              open: false,
            },
          } as BlockStateUnionType);
        },
      },
    ];
  }

  get initialStateGetter() {
    return (block: BlockType) => ({
      id: block.id,
      ..._pick(block.component as DrawerComponentType, ['content', 'appearance', 'componentType']),
      state: {
        open: false,
      },
    });
  }
}

export default DrawerBlockStateDefinition;
