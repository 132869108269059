import type { BlockStateDefinition } from '@unifyapps/carbon/no-code/components/BlockDefinition';
import type {
  BlockType,
  BlockStateUnionType,
  ComponentTypeUnionType,
} from '@unifyapps/defs/types/block';
import _pick from 'lodash/pick';
import type {
  CarouselComponentStateType,
  CarouselComponentType,
} from '@unifyapps/defs/blocks/Carousel/types';
import { lazy } from 'react';

const Carousel = lazy(
  () =>
    import(
      /* webpackChunkName: "carousel-block" */
      './Carousel'
    ),
);

class CarouselBlockStateDefinition implements BlockStateDefinition {
  get type(): ComponentTypeUnionType {
    return 'Carousel';
  }

  getEventTargetIds() {
    return [];
  }

  getBlockControlMethods() {
    return [
      {
        id: 'moveToNextPage',
        label: 'Move to next slide',
        method: (blockState: CarouselComponentStateType) => {
          return {
            ...blockState,
            content: {
              ...blockState.content,
              currentSlideIndex: Math.min(
                blockState.content.currentSlideIndex + 1,
                blockState.content.totalSlidesCount - 1,
              ),
            },
          } as CarouselComponentStateType;
        },
      },
      {
        id: 'moveToPrevPage',
        label: 'Move to previous slide',
        method: (blockState: BlockStateUnionType) => {
          return {
            ...blockState,
            content: {
              ...blockState.content,
              currentSlideIndex: Math.max(
                0,
                (blockState as CarouselComponentStateType).content.currentSlideIndex - 1,
              ),
            },
          } as CarouselComponentStateType;
        },
      },
    ];
  }

  get initialStateGetter() {
    return (block: BlockType) =>
      ({
        id: block.id,
        ..._pick(block.component, ['appearance', 'componentType']),
        content: {
          ...(block.component as CarouselComponentType).content,
          currentSlideIndex: 0,
          totalSlidesCount: 0,
        },
      }) as CarouselComponentStateType;
  }

  getComponent() {
    return Carousel;
  }
}

export default CarouselBlockStateDefinition;
