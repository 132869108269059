'use client';
import { memo, Suspense, useMemo } from 'react';
import type { BlockType, ComputedBlockStateUnionType } from '@unifyapps/defs/types/block';
import type { DrawerBlockStateType } from '@unifyapps/defs/blocks/Drawer/types';
import type {
  ContextualDialogBlockStateType,
  ModalBlockStateType,
} from '@unifyapps/defs/types/blocks';
import { compose } from '../../../utils/compose';
import useIsBlockVisibility from '../../hooks/useIsBlockVisibility';
import { useSetBlockRef } from '../../hooks/useSetBlockRef';
import useContextualDialogEvents from '../../hooks/useContextualDialogEvents';
import { useDataSourceResponses } from '../../hooks/useDataSourceResponses';
import { ErrorBoundary } from '../../../components/ErrorBoundary';
import useSetBlockState from '../../hooks/useSetBlockState';
import { useTriggerAutomaticDataSources } from '../DataSourceController/hooks/useTriggerAutomaticDataSources';
import type { BlockRendererProps, BlockRendererWrapperProps } from '../RegistryProvider/types';
import { useRegistryContext } from '../RegistryProvider';
import { useBlockDataAttributes } from '../../hooks/useBlockDataAttributes';
import { InterfaceModes, useBlockFromPage, useInterfaceStore } from '../../stores/InterfaceStore';
import { isEmptyBlock } from '../../utils/block';
import { useCanAccess } from '../../access-control/hooks/useCanAccess';
import { PlaceholderBlock } from '../PlaceholderBlock';
import useComputeBlockState from '../../hooks/useComputeBlockState';
import type { ComponentType } from '../BlockDefinition';

function NoopComponent() {
  return null;
}

function BlockRenderer({ block, blockState, updateBlockState }: BlockRendererProps) {
  const { id, component, dataSourceIds, events, nonBlockingDataSourceIds, fallback } = block;
  const { registry } = useRegistryContext();
  const Block = registry.getBlockComponent(component.componentType) as ComponentType | undefined;
  const activeInterfacePageId = useInterfaceStore().use.activeInterfacePageId();
  const { isSuspended } = useTriggerAutomaticDataSources({
    dataSourceIds,
    nonBlockingDataSourceIds,
  });
  const { blockRef } = useSetBlockRef({
    blockState,
  });
  const computedBlockState = useComputeBlockState<ComputedBlockStateUnionType>(blockState);
  const { dependentBlockEvents } = useContextualDialogEvents({
    blockId: id,
    blockStateId: blockState?.id, //blockStateId will be different from blockId in case of runtime blocks
  });
  const { dataSourceResponses } = useDataSourceResponses(block);
  const dataAttributes = useBlockDataAttributes({
    id,
    component,
    pageId: activeInterfacePageId,
    blockStateId: blockState?.id, //blockStateId will be different from blockId in case of runtime blocks
  });

  // Merge default events with dependent block events
  // Dependent block events are events that are automatically configured by platform for a block
  // Keeping dependentBlockEvents first because we want to fire them first otherwise they might not be fired
  // if events before it are taking too much time
  const allEvents = useMemo(() => {
    const defaultEvents = events || [];
    const _dependentBlockEvents = dependentBlockEvents || [];
    return [..._dependentBlockEvents, ...defaultEvents];
  }, [events, dependentBlockEvents]);

  if (!Block) {
    console.error(
      'Component type not recognized: ',
      component,
      ' in BlocksRegistry ',
      registry.getRegistryName(),
    );
    return null; // or some fallback UI
  }

  return (
    <Suspense fallback={null}>
      <ErrorBoundary fallback={<NoopComponent />}>
        {/* Assuming your components accept a prop named 'component' that matches their expected type */}
        <Block
          blockRef={blockRef}
          //@ts-expect-error blockState is undefined for login screen it handles on its own correcting type so it doesn't throw error
          blockState={blockState}
          component={component}
          computedBlockState={computedBlockState}
          dataAttributes={dataAttributes}
          dataSourceIds={dataSourceIds}
          dataSourceResponses={dataSourceResponses}
          events={allEvents}
          fallback={fallback}
          isSuspended={isSuspended}
          key={id}
          updateBlockState={updateBlockState}
        />
      </ErrorBoundary>
    </Suspense>
  );
}

const withDetachedBlockCheck = (WrappedComponent: React.ComponentType<BlockRendererProps>) => {
  return function WithDetachedBlockWrapper(props: BlockRendererProps) {
    const { block, blockState } = props;
    const isDetachedBlock =
      block.component.componentType === 'Modal' ||
      block.component.componentType === 'Drawer' ||
      block.component.componentType === 'ContextualDialog';

    // we are evicting from mounting these blocks because they are not visible, so we don't want to mount them
    // this is only applicable for detached blocks
    if (
      isDetachedBlock &&
      !(blockState as DrawerBlockStateType | ModalBlockStateType | ContextualDialogBlockStateType)
        .state.open
    ) {
      return null;
    }

    return <WrappedComponent {...props} />;
  };
};

const withBlockState = (WrappedComponent: React.ComponentType<BlockRendererProps>) => {
  return function WithBlockStateWrapper({ block }: BlockRendererProps) {
    const { id } = block;
    const { blockState, updateBlockState } = useSetBlockState(id);

    return (
      <WrappedComponent block={block} blockState={blockState} updateBlockState={updateBlockState} />
    );
  };
};

const withVisibilityAndPermissionsCheck = (
  WrappedComponent: React.ComponentType<{
    block: BlockType;
  }>,
) => {
  return function WithVisibilityAndPermissionsWrapper({
    blockId,
    parentBlockId,
  }: BlockRendererWrapperProps) {
    const block = useBlockFromPage({ blockId });
    const interfaceMode = useInterfaceStore().use.mode();
    const isBlockPermissible = useCanAccess(block?.permissions);
    const { getIsBlockVisibility } = useIsBlockVisibility();

    if (!isBlockPermissible) {
      return null;
    }

    if (isEmptyBlock(blockId)) {
      if (interfaceMode === InterfaceModes.BUILDER) {
        return <PlaceholderBlock parentBlockId={parentBlockId} />;
      }
      return null;
    }

    if (!getIsBlockVisibility(block?.visibility)) {
      return null;
    }

    if (block) {
      return <WrappedComponent block={block} />;
    }

    console.error(`Unexpected scenario! Block with id ${blockId} not found.`);
    return null;
  };
};

const ComposedBlockRenderer = compose<React.ComponentType<BlockRendererWrapperProps>>(
  withVisibilityAndPermissionsCheck,
  withBlockState,
  withDetachedBlockCheck,
  memo,
)(BlockRenderer);

export default ComposedBlockRenderer;
