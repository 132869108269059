import type { BlockStateDefinition } from '@unifyapps/carbon/no-code/components/BlockDefinition';
import type {
  BlockType,
  ComponentTypeUnionType,
  BlockStateUnionType,
  ComponentsUnionType,
  ComputedBlockStateUnionType,
} from '@unifyapps/defs/types/block';
import _pick from 'lodash/pick';
import type { MenuComponentType, MenuComponentStateType } from '@unifyapps/defs/blocks/Menu/types';
import { MenuContentType } from '@unifyapps/defs/blocks/Menu/types';
import { EMPTY_ARRAY } from '@unifyapps/carbon/consts/empty';
import { lazy } from 'react';
import type { BlockComponentProps } from '@unifyapps/carbon/no-code/components/BlockRenderer/types';
import type { MemoExoticComponent } from 'react';

type LoadMenuType = MemoExoticComponent<
  (
    props: BlockComponentProps<
      ComponentsUnionType,
      BlockStateUnionType,
      ComputedBlockStateUnionType
    >,
  ) => React.ReactNode
> | null;

const importMenu = () =>
  import(
    /* webpackChunkName: "menu-block" */
    './Menu'
  );

const Menu = lazy(importMenu);

class MenuBlockStateDefinition implements BlockStateDefinition {
  private static loadedMenu = null as LoadMenuType | null;

  get type(): ComponentTypeUnionType {
    return 'Menu';
  }

  getEventTargetIds(block: BlockType<MenuComponentType>) {
    if (block.component.content.type === MenuContentType.STATIC) {
      return (
        block.component.content.menuItems?.map((item) => item.value) ?? (EMPTY_ARRAY as string[])
      );
    }

    return EMPTY_ARRAY as string[];
  }

  getBlockControlMethods() {
    return [];
  }

  get initialStateGetter() {
    return (block: BlockType) =>
      ({
        id: block.id,
        ..._pick(block.component as MenuComponentType, ['appearance', 'componentType', 'content']),
        selectedItem: {
          id: (block.component as MenuComponentType).content.initialSelectedMenuItemId,
        },
      }) as MenuComponentStateType;
  }

  async preloadBlock() {
    // If the component is already loaded, return
    if (MenuBlockStateDefinition.loadedMenu) {
      return;
    }

    // Load the component if not already done
    const MenuComponent = (await importMenu()) as {
      default: LoadMenuType;
    };

    // Store the loaded component and return it
    MenuBlockStateDefinition.loadedMenu = MenuComponent.default;
  }

  getComponent() {
    return MenuBlockStateDefinition.loadedMenu ?? Menu;
  }
}

export default MenuBlockStateDefinition;
