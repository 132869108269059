import type { BlockComponentProps } from '@unifyapps/carbon/no-code/components/BlockRenderer/types';
import { useBlockAppearanceStyles } from '@unifyapps/carbon/no-code/hooks/useBlockAppearanceStyles';
import type {
  MessageInputBlockStateType,
  MessageInputComponentType,
} from '@unifyapps/defs/blocks/MessageInput/types';
import useBlockEvents from '@unifyapps/carbon/no-code/hooks/useBlockEvents';
import { useCallback, memo } from 'react';
import { EventTypeEnum } from '@unifyapps/defs/types/event';
import useEventCallback from '@unifyapps/hooks/useEventCallback';
import { MessageInput } from '@unifyapps/carbon/components/MessageInput/MessageInput';
import type { FileType } from '@unifyapps/defs/blocks/FileUpload/types';

function MessageInputBlock({
  dataAttributes,
  events,
  updateBlockState,
  computedBlockState,
}: BlockComponentProps<
  MessageInputComponentType,
  MessageInputBlockStateType,
  MessageInputBlockStateType
>) {
  const {
    content: { placeholder, allowAttachmentsUpload },
    appearance,
    value,
  } = computedBlockState;

  const { className, style } = useBlockAppearanceStyles({
    appearanceStyles: appearance?.styles,
  });
  const { emitEvent } = useBlockEvents(events);

  const onChange = useEventCallback((newValue: string) => {
    updateBlockState((draft) => {
      draft.value = newValue;
    });
  });

  const onAttachmentsUpdate = useEventCallback((files: FileType[]) => {
    updateBlockState((draft) => {
      draft.attachments = files;
    });
  });

  const onSubmitMessage = useCallback(() => {
    void emitEvent({
      eventType: EventTypeEnum.OnSendMessage,
    });
    onChange('');
  }, [emitEvent, onChange]);

  return (
    <MessageInput
      allowAttachmentsUpload={allowAttachmentsUpload}
      className={className}
      disabled={appearance?.disabled}
      onAttachmentsUpdate={onAttachmentsUpdate}
      onChangeValue={onChange}
      onSubmitMessage={onSubmitMessage}
      placeholder={placeholder}
      style={style}
      value={value}
      {...dataAttributes}
    />
  );
}

export default memo(MessageInputBlock);
