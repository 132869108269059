import type { BlockStateDefinition } from '@unifyapps/carbon/no-code/components/BlockDefinition';
import type { BlockType, ComponentTypeUnionType } from '@unifyapps/defs/types/block';
import _pick from 'lodash/pick';
import type {
  StepperComponentStateType,
  StepperComponentType,
} from '@unifyapps/defs/blocks/Stepper/types';
import { StepperContentType } from '@unifyapps/defs/blocks/Stepper/types';
import _findIndex from 'lodash/findIndex';
import { lazy } from 'react';

const Stepper = lazy(
  () =>
    import(
      /* webpackChunkName: "stepper-block" */
      './Stepper'
    ),
);

class StepperBlockStateDefinition implements BlockStateDefinition {
  get type(): ComponentTypeUnionType {
    return 'Stepper';
  }

  getEventTargetIds() {
    return [];
  }

  getBlockControlMethods() {
    return [
      {
        id: 'moveToNextStep',
        label: 'Move to next step',
        method: (blockState: StepperComponentStateType) => {
          return {
            ...blockState,
            activeStepIndex: Math.min(blockState.activeStepIndex + 1, blockState.totalSteps - 1),
          } as StepperComponentStateType;
        },
      },
      {
        id: 'moveToPrevStep',
        label: 'Move to previous step',
        method: (blockState: StepperComponentStateType) => {
          return {
            ...blockState,
            activeStepIndex: Math.max(blockState.activeStepIndex - 1, 0),
          } as StepperComponentStateType;
        },
      },
    ];
  }

  get initialStateGetter() {
    return (block: BlockType) => {
      const component = block.component as StepperComponentType;

      let activeIndex = -1;

      if (
        component.content.type === StepperContentType.STATIC &&
        component.content.defaultStepValue &&
        !(
          typeof component.content.defaultStepValue === 'object' &&
          'conditionalValueFilters' in component.content.defaultStepValue
        )
      ) {
        activeIndex = _findIndex(component.content.steps, {
          value: component.content.defaultStepValue,
        });
      }

      return {
        id: block.id,
        ..._pick(block.component, ['appearance', 'componentType', 'content']),
        activeStepIndex: activeIndex === -1 ? 0 : activeIndex,
        totalSteps: 0,
      } as StepperComponentStateType;
    };
  }

  getComponent() {
    return Stepper;
  }
}

export default StepperBlockStateDefinition;
