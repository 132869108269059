import type { BlockStateDefinition } from '@unifyapps/carbon/no-code/components/BlockDefinition';
import type {
  BlockType,
  BlockStateUnionType,
  ComponentTypeUnionType,
} from '@unifyapps/defs/types/block';
import _pick from 'lodash/pick';
import { lazy } from 'react';

const StatCard = lazy(
  () =>
    import(
      /* webpackChunkName: "stat-card-block" */
      './StatCard'
    ),
);

class StatCardBlockStateDefinition implements BlockStateDefinition {
  getComponent() {
    return StatCard;
  }

  get type(): ComponentTypeUnionType {
    return 'StatCard';
  }

  getEventTargetIds() {
    return [];
  }

  getBlockControlMethods() {
    return [];
  }

  get initialStateGetter() {
    return (block: BlockType) =>
      ({
        id: block.id,
        ..._pick(block.component, ['content', 'appearance', 'componentType']),
      }) as BlockStateUnionType;
  }
}

export default StatCardBlockStateDefinition;
