import React, { memo, useImperativeHandle, useMemo, useRef } from 'react';
import type { BlockComponentProps } from '@unifyapps/carbon/no-code/components/BlockRenderer/types';
import type {
  CopilotBlockRef,
  CopilotBlockStateType,
  CopilotComponentType,
} from '@unifyapps/defs/blocks/Copilot/types';
import { useTranslation } from '@unifyapps/i18n/client';
import userStore from '@unifyapps/carbon/auth/userStore';
import useFetchAIConfig, { ConfigType } from '@unifyapps/carbon/copilot/hooks/useFetchAIConfig';
import { Box } from '@unifyapps/ui/components/Box';
import Stack from '@unifyapps/ui/_components/Stack';
import BlockRenderer from '@unifyapps/carbon/no-code/components/BlockRenderer/BlockRenderer';
import { useBlockAppearanceStyles } from '@unifyapps/carbon/no-code/hooks/useBlockAppearanceStyles';
import AsyncView from '@unifyapps/carbon/components/AsyncView';
import ChatContext from '../Chat/components/ChatContext';
import InitialAwaitingContext from '../Chat/hooks/useInitialAwaitingContext';
import { Chat } from '../Chat';
import type { ChatRef } from '../Chat/Chat';
import {
  CitationPanelProvider,
  useCitationsPanelContext,
} from '../Chat/components/Citation/components/CitationsPanelProvider';
import { CitationsPanel } from '../Chat/components/Citation/components/CitationsPanel';
import { useQueryParams } from './hooks/useQueryParams';
import { useBlockUpdateStateEffect } from './hooks/useBlockUpdateStateEffect';
import { getChatIdQueryParamKey } from './utils';
import { useChatPage } from './hooks/useChatPage';
import { useGetCopilotKey } from './hooks/useGetCopilotKey';

function Copilot({
  component,
  updateBlockState,
  blockRef,
  dataAttributes,
  computedBlockState,
}: BlockComponentProps<
  CopilotComponentType,
  CopilotBlockStateType,
  CopilotBlockStateType,
  CopilotBlockRef
>) {
  const {
    content: { quickQuestions, welcomeText, aiAgentId, filters, initialChatId },
    chatId,
    appearance,
    errorMessage,
    id,
  } = computedBlockState;

  const { className, style } = useBlockAppearanceStyles({
    appearanceStyles: appearance?.styles,
  });
  const chatRef = useRef<ChatRef>(null);
  const chatIdQueryParamKey = getChatIdQueryParamKey(id);

  const { setQueryParam } = useQueryParams();
  const { t } = useTranslation(['blocks']);

  const userId = userStore.use.currentUserDetails()?.user?.id;

  const { config: automationsConfig, isLoading, error } = useFetchAIConfig(ConfigType.AIAgentTest);
  const { isPanelOpen } = useCitationsPanelContext();

  const chatComponent = useMemo(
    () => ({
      componentType: 'Chat' as const,
      content: {
        chatId: chatId === 'new' ? undefined : chatId,
        placeholder: t('blocks:chat.Placeholder'),
        automationsConfig,
        exampleMessages: quickQuestions,
        welcomeText,
        secondaryUserName: 'Copilot',
        size: appearance?.size ?? 'lg',
      },
    }),
    [appearance?.size, automationsConfig, chatId, quickQuestions, t, welcomeText],
  );

  const chatContext = useChatPage({
    chatId,
    aiAgentId,
    chatIdQueryParamKey,
    filters,
    automationsConfig,
  });

  useImperativeHandle(blockRef, () => ({
    submitMessage: chatRef.current?.onSubmitMessage,
    createNewChat: () => {
      setQueryParam(chatIdQueryParamKey, 'new');
    },
    goToChat: (_chatId?: string) => {
      setQueryParam(chatIdQueryParamKey, _chatId ?? 'new');
    },
  }));

  useBlockUpdateStateEffect({
    errorMessage,
    updateBlockState,
    automationsConfig,
    aiAgentId,
    chatIdQueryParamKey,
    initialChatId,
  });

  return (
    <AsyncView
      data={automationsConfig}
      error={error}
      isLoading={isLoading}
      renderEmptyState={undefined}
    >
      <Box className={className} style={style} {...dataAttributes}>
        <Stack className="rounded-ts-3xl size-full" direction="row">
          {component.slots?.messageHistory?.blockId && userId ? (
            <Stack className="rounded-ts-3xl h-full overflow-y-auto">
              <BlockRenderer blockId={component.slots.messageHistory.blockId} />
            </Stack>
          ) : null}
          <Stack className="size-full flex-1" direction="row">
            <Stack className="h-full w-full flex-1">
              <Stack className="flex-1 overflow-hidden">
                <ChatContext.Provider value={chatContext}>
                  <Chat component={chatComponent} ref={chatRef} />
                </ChatContext.Provider>
              </Stack>
              {component.slots?.messageInputPanel?.blockId ? (
                <BlockRenderer blockId={component.slots.messageInputPanel.blockId} />
              ) : null}
            </Stack>
            {isPanelOpen ? <CitationsPanel /> : null}
          </Stack>
        </Stack>
      </Box>
    </AsyncView>
  );
}

function CopilotWrapper(
  props: BlockComponentProps<
    CopilotComponentType,
    CopilotBlockStateType,
    CopilotBlockStateType,
    CopilotBlockRef
  >,
) {
  const { getQueryParam } = useQueryParams();
  const chatId = getQueryParam(getChatIdQueryParamKey(props.computedBlockState.id)) ?? undefined;

  const copilotKey = useGetCopilotKey(chatId);

  return (
    <InitialAwaitingContext key={copilotKey}>
      <CitationPanelProvider>
        <Copilot {...props} />
      </CitationPanelProvider>
    </InitialAwaitingContext>
  );
}

export default memo(CopilotWrapper);
