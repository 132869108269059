import type { BlockStateDefinition } from '@unifyapps/carbon/no-code/components/BlockDefinition';
import type {
  ComponentTypeUnionType,
  BlockType,
  BlockStateUnionType,
} from '@unifyapps/defs/types/block';
import type { SteppedContainerComponentType } from '@unifyapps/defs/blocks/SteppedContainer/types';
import _pick from 'lodash/pick';
import { lazy } from 'react';

const SteppedContainer = lazy(
  () =>
    import(
      /* webpackChunkName: "stepped-container-block" */
      './SteppedContainer'
    ),
);

class SteppedContainerBlockStateDefinition implements BlockStateDefinition {
  get type(): ComponentTypeUnionType {
    return 'SteppedContainer';
  }

  getEventTargetIds() {
    return [];
  }

  getBlockControlMethods() {
    return [];
  }

  get initialStateGetter() {
    return (block: BlockType) =>
      ({
        id: block.id,
        ..._pick(block.component as SteppedContainerComponentType, [
          'content',
          'componentType',
          'appearance',
        ]),
      }) as BlockStateUnionType;
  }

  getComponent() {
    return SteppedContainer;
  }
}

export default SteppedContainerBlockStateDefinition;
