import { getIdWithPrefix } from '@unifyapps/carbon/utils/id';
import { BLOCK_PREFIX } from '@unifyapps/carbon/no-code/const';
import type { WorkflowInputFormData } from '@unifyapps/defs/blocks/Chat/types';
import type { FormAppearance, FormComponentType } from '@unifyapps/defs/blocks/Form/types';
import type { UISchema } from '@unifyapps/carbon/widgets/SchemaGeneratorWidget/types';

export const generateFormBlock = ({
  data,
  schema,
  layout,
  appearance,
}: {
  data: WorkflowInputFormData;
  appearance?: FormAppearance;
  schema: Record<string, unknown>;
  layout: UISchema;
}): {
  blocks: Record<string, { id: string; component: FormComponentType }>;
  containerId: string;
} => {
  const blockId = getIdWithPrefix(BLOCK_PREFIX);

  return {
    containerId: blockId,
    blocks: {
      [blockId]: {
        id: blockId,
        component: {
          componentType: 'Form',
          content: {
            source: 'custom',
            schema: {
              schema,
              layout,
            },
            readonly: true,
            initialFormData: data.value,
          },
          appearance: { ...(appearance ?? {}) },
        },
      },
    },
  };
};
