import type {
  BlockStateDefinition,
  BlockControlMethodHelpers,
} from '@unifyapps/carbon/no-code/components/BlockDefinition';
import type { SelectedPoint } from '@unifyapps/defs/blocks/Chart/types';
import type {
  BlockType,
  BlockStateUnionType,
  ComponentTypeUnionType,
} from '@unifyapps/defs/types/block';
import _pick from 'lodash/pick';
import type { Action } from '@unifyapps/defs/types/action';
import { lazy } from 'react';
import { BlockDataAttributes } from '@unifyapps/carbon/no-code/hooks/useBlockDataAttributes';

const Chart = lazy(
  () =>
    import(
      /* webpackChunkName: "chart-block" */
      './Chart'
    ),
);

class ChartBlockStateDefinition implements BlockStateDefinition {
  get type(): ComponentTypeUnionType {
    return 'Chart';
  }

  getEventTargetIds() {
    return [];
  }

  getBlockControlMethods() {
    return [
      {
        id: 'setSelectedDataPoint' as const,
        label: 'Select Data Point',
        method: (blockState: BlockStateUnionType, action: Action) => {
          const { methodPayload } = action.payload as {
            methodPayload: SelectedPoint;
          };

          return {
            ...blockState,
            selectedPoints: [methodPayload],
          } as BlockStateUnionType;
        },
      },
      {
        id: 'clearSelectedDataPoint' as const,
        label: 'Clear Selected Data Point',
        method: (blockState: BlockStateUnionType) => {
          return {
            ...blockState,
            selectedPoints: [],
          } as BlockStateUnionType;
        },
      },
      {
        id: 'generatePreview' as const,
        label: 'Generate Preview',
        method: async (
          blockState: BlockStateUnionType,
          action: Action,
          blockRef: unknown,
          helpers: BlockControlMethodHelpers,
        ) => {
          try {
            const blockEl = document.querySelector<HTMLElement>(
              `[${BlockDataAttributes.BlockId}="${blockState.id}"]`,
            );

            if (!blockEl) {
              helpers.showSnackbar({
                title: 'Something went wrong while generating preview',
                color: 'error',
              });
              console.error('Error generating preview: ', 'Block element not found');
              return blockState;
            }

            const html2canvas = await import('html2canvas');
            const canvas = await html2canvas.default(blockEl);

            const image = canvas.toDataURL('image/png');

            return {
              ...blockState,
              preview: image,
            };
          } catch (error) {
            helpers.showSnackbar({
              title: 'Something went wrong while generating preview',
              color: 'error',
            });
            console.error('Error generating preview', error);
            return blockState;
          }
        },
      },
    ];
  }

  get initialStateGetter() {
    return (block: BlockType) =>
      ({
        id: block.id,
        ..._pick(block.component, ['content', 'appearance', 'componentType']),
      }) as BlockStateUnionType;
  }

  getComponent() {
    return Chart;
  }
}

export default ChartBlockStateDefinition;
