import type { BlockStateDefinition } from '@unifyapps/carbon/no-code/components/BlockDefinition';
import type {
  BlockType,
  BlockStateUnionType,
  ComponentTypeUnionType,
} from '@unifyapps/defs/types/block';
import _pick from 'lodash/pick';
import { lazy } from 'react';

const Icon = lazy(
  () =>
    import(
      /* webpackChunkName: "icon-block" */
      './Icon'
    ),
);

class IconBlockStateDefinition implements BlockStateDefinition {
  getComponent() {
    return Icon;
  }

  get type(): ComponentTypeUnionType {
    return 'Icon';
  }

  getEventTargetIds() {
    return [];
  }

  getBlockControlMethods() {
    return [];
  }

  get initialStateGetter() {
    return (block: BlockType) =>
      ({
        id: block.id,
        ..._pick(block.component, ['content', 'appearance', 'componentType']),
      }) as BlockStateUnionType;
  }
}

export default IconBlockStateDefinition;
