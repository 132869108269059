import type { BlockStateDefinition } from '@unifyapps/carbon/no-code/components/BlockDefinition';
import type { BlockType, ComponentTypeUnionType } from '@unifyapps/defs/types/block';
import type { TagComponentType } from '@unifyapps/defs/types/blocks';
import _pick from 'lodash/pick';
import { lazy } from 'react';

const Tag = lazy(
  () =>
    import(
      /* webpackChunkName: "tag-block" */
      './Tag'
    ),
);

class TagBlockStateDefinition implements BlockStateDefinition {
  get type(): ComponentTypeUnionType {
    return 'Tag';
  }

  getEventTargetIds() {
    return [];
  }

  getBlockControlMethods() {
    return [];
  }

  get initialStateGetter() {
    return (block: BlockType) => ({
      id: block.id,
      ..._pick(block.component as TagComponentType, ['content', 'appearance', 'componentType']),
    });
  }

  getComponent() {
    return Tag;
  }
}

export default TagBlockStateDefinition;
