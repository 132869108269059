import type { BlockStateDefinition } from '@unifyapps/carbon/no-code/components/BlockDefinition';
import type {
  ComponentTypeUnionType,
  BlockType,
  BlockStateUnionType,
} from '@unifyapps/defs/types/block';
import _pick from 'lodash/pick';
import { lazy } from 'react';

const Progress = lazy(
  () =>
    import(
      /* webpackChunkName: "progress-block" */
      './Progress'
    ),
);

class ProgressBlockStateDefinition implements BlockStateDefinition {
  getComponent() {
    return Progress;
  }

  get type(): ComponentTypeUnionType {
    return 'ProgressBar';
  }

  getEventTargetIds() {
    return [];
  }

  getBlockControlMethods() {
    return [];
  }

  get initialStateGetter() {
    return (block: BlockType) =>
      ({
        id: block.id,
        ..._pick(block.component, ['content', 'appearance', 'componentType']),
      }) as BlockStateUnionType;
  }
}

export default ProgressBlockStateDefinition;
