import type { BlockComponentProps } from '@unifyapps/carbon/no-code/components/BlockRenderer/types';
import type {
  ButtonComponentType,
  ButtonBlockStateType,
  ComputedButtonBlockStateType,
} from '@unifyapps/defs/types/blocks';
import type { SyntheticEvent } from 'react';
import React, { useCallback, useMemo, memo } from 'react';
import useBlockEvents from '@unifyapps/carbon/no-code/hooks/useBlockEvents';
import { useBlockAppearanceStyles } from '@unifyapps/carbon/no-code/hooks/useBlockAppearanceStyles';
import { EventTypeEnum } from '@unifyapps/defs/types/event';
import dynamic from 'next/dynamic';
import { useButtonPreConfiguredEvents } from './useButtonPreConfiguredEvents';
import BaseButton from './components/BaseButton';

const ReCaptchaButton = dynamic(
  () =>
    import(
      /* webpackChunkName: "ReCaptchaButton" */
      './components/ReCaptchaButton'
    ),
);

function Button({
  dataAttributes,
  events,
  computedBlockState,
  updateBlockState,
}: BlockComponentProps<ButtonComponentType, ButtonBlockStateType, ComputedButtonBlockStateType>) {
  const { content, appearance } = computedBlockState;
  const buttonPreConfiguredEvents = useButtonPreConfiguredEvents(computedBlockState);
  const _events = useMemo(
    () => [...buttonPreConfiguredEvents, ...(events ?? [])],
    [events, buttonPreConfiguredEvents],
  );

  const { reCaptchaSiteKey } = content.addOns?.reCaptcha ?? {};

  // used to check if we need recaptcha in our button
  const isCaptchaNeeded = Boolean(reCaptchaSiteKey);

  // in BUTTON we are only handling onClick event, for any new event handling handle it for recaptcha too
  const { emitEvent } = useBlockEvents(_events);

  const onClick = useCallback(
    (e: SyntheticEvent) => {
      void emitEvent({ eventType: EventTypeEnum.OnClick, domEvent: e });
    },
    [emitEvent],
  );

  const { className, style } = useBlockAppearanceStyles({
    appearanceStyles: appearance?.styles,
  });

  const type = content.type ?? 'default';
  const size = appearance?.size ?? 'md';

  const baseButtonProps = {
    appearance,
    className,
    dataAttributes,
    onClick,
    size,
    style,
    type,
    value: content.value,
  };

  if (isCaptchaNeeded) {
    return (
      <React.Suspense fallback={<BaseButton {...baseButtonProps} onClick={undefined} />}>
        <ReCaptchaButton
          baseButtonProps={baseButtonProps}
          emitEvent={emitEvent}
          reCaptcha={content.addOns?.reCaptcha}
          reCaptchaToken={computedBlockState.reCaptchaToken}
          updateBlockState={updateBlockState}
        />
      </React.Suspense>
    );
  }

  return <BaseButton {...baseButtonProps} />;
}

export default memo(Button);
