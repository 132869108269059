import { Box } from '@unifyapps/ui/components/Box';
import type { TypographyVariants } from '@unifyapps/ui/components/Typography';
import { Typography } from '@unifyapps/ui/components/Typography';
import Stack from '@unifyapps/ui/_components/Stack';
import { clsx } from 'clsx';
import { cva } from 'class-variance-authority';
import { useTranslation } from '@unifyapps/i18n/client';
import { Tooltip } from '@unifyapps/ui/components/Tooltip';
import SvgXClose from '@unifyapps/icons/outline/XClose';
import useEventCallback from '@unifyapps/hooks/useEventCallback';
import { SmallIconButton } from '@unifyapps/ui/components/SmallIconButton';
import type { MediaItemProps } from '../types';
import { MediaItem } from './MediaItem';
import './style.css';
import { getMediaItem } from './CompactMediaItem';

const iconVariants = cva('', {
  variants: {
    size: {
      sm: 'size-6',
      md: 'size-10',
      lg: 'size-16',
    },
  },
});

const typographyVariants = cva('', {
  variants: {
    size: {
      sm: 'text-xxs',
      md: 'text-xs',
      lg: 'text-xs',
    },
  },
});

const captionVariants = cva('flex items-center border-secondary border-t flex-shrink-0', {
  variants: {
    size: {
      sm: 'p-xs h-4',
      md: 'p-md h-6',
      lg: 'p-md h-8',
    },
  },
});

const sizeVariants = cva('', {
  variants: {
    size: {
      sm: 'size-16',
      md: 'h-[120px] w-[120px]',
      lg: 'h-[174px] w-[174px]',
    },
  },
});

const imgClassNameVariants = cva('w-full h-full', {
  variants: {
    size: {
      sm: '',
      md: '',
      lg: '',
    },
    showCaption: {
      true: '',
      false: '',
    },
  },
  compoundVariants: [
    {
      size: 'sm',
      showCaption: true,
      className: 'max-h-12',
    },
    {
      size: 'md',
      showCaption: true,
      className: 'max-h-16',
    },
    {
      size: 'lg',
      showCaption: true,
      className: 'max-h-[142px]',
    },
  ],
});

export function SpaciousMediaItem({
  item,
  className,
  onClick,
  size,
  showCaption: _showCaption,
  onRemoveItem,
  overrides,
}: MediaItemProps) {
  const { t } = useTranslation('common');
  const iconClassName = iconVariants({ size });
  const variant = typographyVariants({ size }) as TypographyVariants;

  const handleDelete: React.MouseEventHandler<HTMLButtonElement> = useEventCallback((event) => {
    event.stopPropagation();
    onRemoveItem?.(item.id);
  });

  const containerClassName = clsx(sizeVariants({ size }), className);

  const OverriddenMediaItem = getMediaItem(item, overrides);
  if (OverriddenMediaItem) {
    return (
      <OverriddenMediaItem
        className={containerClassName}
        item={item}
        onClick={onClick}
        onRemoveItem={onRemoveItem}
        size={size}
      />
    );
  }
  const showCaption = _showCaption || item.showCaption;

  return (
    <Tooltip
      arrow={false}
      disabled={showCaption}
      offset={4}
      title={item.name || t('common:Attachment')}
    >
      <Stack
        className={clsx(
          'bg-primary border-secondary group relative cursor-pointer rounded-sm border',
          containerClassName,
        )}
        onClick={onClick}
      >
        <Box className="flex flex-1 items-center justify-center">
          <MediaItem
            iconClassName={iconClassName}
            imgClassName={clsx(
              showCaption ? 'rounded-t-sm' : 'rounded-sm',
              imgClassNameVariants({ size, showCaption }),
            )}
            item={item}
          />
        </Box>
        {showCaption ? (
          <Box className={captionVariants({ size })}>
            <Typography maxNumberOfLines={1} variant={variant}>
              {item.caption ?? item.name ?? t('common:Attachment')}
            </Typography>
          </Box>
        ) : null}
        {onRemoveItem ? (
          <Tooltip placement="top" title={t('common:Actions.RemoveItem')}>
            <SmallIconButton
              Icon={SvgXClose}
              className="btnClassName absolute -end-2 -top-2 hidden justify-self-end group-hover:flex"
              onClick={handleDelete}
              size="sm"
              variant="solid"
            />
          </Tooltip>
        ) : null}
      </Stack>
    </Tooltip>
  );
}
