'use client';

import React from 'react';
import type { UseFetchResourcesHookType } from '../../hooks/fetchAndInvalidation/fetch/useFetchInterfaceResources/type';
import type { DeviceDetailsType } from '../../stores/GlobalStateStore';
import useFetchInterfaceResources from '../../hooks/fetchAndInvalidation/fetch/useFetchInterfaceResources';
import AsyncView from '../../../components/AsyncView';
import {
  InterfaceClient,
  InterfaceModes,
  type InterfaceStoreState,
} from '../../stores/InterfaceStore';
import { InterfaceResourceProvider } from '../InterfaceResourceProvider';
import type { InterfaceResourceProviderProps } from '../InterfaceResourceProvider/types';
import type { InterfaceComponent } from '../../types/interfaceComponent';
import './defaultStyles.scss';

export type InterfaceProviderProps = Pick<
  InterfaceResourceProviderProps,
  'basePath' | 'registry' | 'emitPageEvent' | 'isPublic' | 'pageInputs'
> & {
  interfaceSlug: string;
  interfaceComponent: InterfaceComponent;
  children: React.ReactNode;
  disableInteractiveMode?: boolean;
  useFetchResources?: UseFetchResourcesHookType;
  renderFallback?: () => JSX.Element;
  deviceDetails?: DeviceDetailsType;
  mode?: InterfaceStoreState['mode'];
  client?: InterfaceStoreState['client'];
};

function InterfaceProvider({
  interfaceSlug: interfaceId,
  interfaceComponent,
  children,
  deviceDetails,
  registry,
  pageInputs,
  emitPageEvent,
  disableInteractiveMode,
  useFetchResources = useFetchInterfaceResources,
  isPublic,
  renderFallback,
  mode = InterfaceModes.RUNNER,
  client = mode === InterfaceModes.RUNNER ? InterfaceClient.UA_MATRIX : InterfaceClient.UA_PLATFORM,
  basePath,
}: InterfaceProviderProps) {
  const { data, dataSources, error, interfacePage, interfaceModulesMap, isLoading } =
    useFetchResources({
      interfaceId,
      interfaceComponent,
      client,
    });

  // Uncomment the following lines to debug ssr
  // const shouldRender = data && interfacePage && !isLoading;
  // console.log('InterfaceProvider:shouldRender', shouldRender);
  // console.log('InterfaceProvider:isLoading', isLoading);

  return (
    <AsyncView
      data={data}
      error={error}
      isLoading={isLoading}
      renderError={renderFallback}
      withSuspense={false}
    >
      {data && interfacePage ? (
        <InterfaceResourceProvider
          basePath={basePath}
          client={client}
          dataSources={dataSources}
          deviceDetails={deviceDetails}
          disableInteractiveMode={disableInteractiveMode}
          emitPageEvent={emitPageEvent}
          interfaceRecord={data}
          isPublic={isPublic}
          mode={mode}
          modules={interfaceModulesMap}
          page={interfacePage}
          pageInputs={pageInputs}
          registry={registry}
        >
          {children}
        </InterfaceResourceProvider>
      ) : null}
    </AsyncView>
  );
}

export default InterfaceProvider;
