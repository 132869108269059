import Attachment02 from '@unifyapps/icons/outline/Attachment02';
import { IconButton } from '@unifyapps/ui/components/IconButton';
import { UploadCard } from '../UploadCard';
import type { useUppy } from '../../hooks/useUppy';

export function AttachmentsUploader({
  referenceId,
  uppy,
  disabled,
}: {
  referenceId: string;
  uppy: ReturnType<typeof useUppy>['uppy'];
  disabled?: boolean;
}) {
  return (
    <UploadCard accept="*" multiple referenceId={referenceId} uppy={uppy}>
      <IconButton
        Icon={Attachment02}
        color="neutral"
        disabled={disabled}
        size="md"
        variant="soft"
      />
    </UploadCard>
  );
}
