import type { BlockComponentProps } from '@unifyapps/carbon/no-code/components/BlockRenderer/types';
import type { SyntheticEvent } from 'react';
import { useCallback, useMemo, memo } from 'react';
import type { ImageComponentType, ImageBlockStateType } from '@unifyapps/defs/blocks/Image/types';
import useBlockEvents from '@unifyapps/carbon/no-code/hooks/useBlockEvents';
import { useBlockAppearanceStyles } from '@unifyapps/carbon/no-code/hooks/useBlockAppearanceStyles';
import { Box } from '@unifyapps/ui/components/Box';
import { clsx } from 'clsx';
import { EventTypeEnum } from '@unifyapps/defs/types/event';
import _some from 'lodash/some';

function Image({
  dataAttributes,
  events,
  computedBlockState,
}: BlockComponentProps<ImageComponentType, ImageBlockStateType, ImageBlockStateType>) {
  const { content, appearance } = computedBlockState;

  const { src, alt } = content;

  const { emitEvent } = useBlockEvents(events);

  const isClickEventPresent = useMemo(
    () => _some(events, { eventType: EventTypeEnum.OnClick }),
    [events],
  );

  const onClick = useCallback(
    (e: SyntheticEvent) => {
      void emitEvent({ eventType: EventTypeEnum.OnClick, domEvent: e });
    },
    [emitEvent],
  );

  const { className, style } = useBlockAppearanceStyles({
    appearanceStyles: appearance?.styles,
  });

  return (
    <Box
      {...dataAttributes}
      className={clsx('overflow-hidden', className)}
      onClick={onClick}
      style={style}
    >
      <img
        alt={alt}
        className={clsx('h-full w-full', appearance?.objectFit, {
          'cursor-pointer': isClickEventPresent,
        })}
        src={src}
      />
    </Box>
  );
}

export default memo(Image);
