import { type ChangeEventHandler, type KeyboardEvent, useCallback, useMemo } from 'react';
import type { TextareaProps } from '@unifyapps/ui/components/Textarea';
import Textarea from '@unifyapps/ui/components/Textarea';
import CornerDownLeft from '@unifyapps/icons/outline/CornerDownLeft';
import { IconButton } from '@unifyapps/ui/components/IconButton';
import { cva } from 'class-variance-authority';
import useControlledState from '@unifyapps/ui/hooks/useControlledState';
import SvgLoading02 from '@unifyapps/icons/outline/Loading02';
import { clsx } from 'clsx';
import type { FileType } from '@unifyapps/defs/blocks/FileUpload/types';
import { UppyProvider } from '../UppyProvider';
import { AttachmentsUploader } from './AttachmentsUploader';
import { useAttachmentsUpload } from './hooks/useAttachmentsUpload';
import { TextareaRoot } from './TextareaRoot';

export type MessageInputProps = {
  onSubmitMessage: (props: {
    message: string | undefined;
    attachments?: FileType[];
  }) => void | Promise<void>;
  placeholder?: string;
  onChange?: (value: string) => void;
  value?: string;
  isLoading?: boolean;
  allowAttachmentsUpload?: boolean;
  onAttachmentsUpdate?: (files: FileType[]) => void;
} & Omit<TextareaProps, 'onChange' | 'value'>;

const chatInputSlotProps = {
  textarea: {
    className: 'my-auto resize-none h-9',
  },
  endDecorator: {
    className: 'mt-0',
  },
  startDecorator: {
    className: 'mb-0',
  },
};

const REFERENCE_ID = 'MESSAGE_INPUT';

const SLOTS = { root: TextareaRoot };

function MessageInput({
  isLoading,
  onSubmitMessage,
  placeholder,
  onChange,
  onAttachmentsUpdate,
  value,
  className,
  slotProps: _slotProps,
  allowAttachmentsUpload = false,
  disabled,
  ...restProps
}: MessageInputProps) {
  const [input, setInput] = useControlledState<string | undefined>({
    onChange,
    controlledValue: value,
  });
  const { uppy, attachments, isUploading, onRemoveAttachment, onClearAll } = useAttachmentsUpload({
    referenceId: REFERENCE_ID,
    onAttachmentsUpdate,
  });

  const onInputSubmit = useCallback(() => {
    if (isUploading || (!input && attachments && attachments.length > 0)) return;
    void onSubmitMessage({ message: input, attachments });
    onClearAll();
  }, [isUploading, onSubmitMessage, input, attachments, onClearAll]);

  const onInputChange = useCallback<ChangeEventHandler<HTMLTextAreaElement>>(
    (event) => {
      setInput(event.target.value);
    },
    [setInput],
  );

  const onKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'Enter' && !event.shiftKey) {
        event.preventDefault();
        onInputSubmit();
      }
    },
    [onInputSubmit],
  );

  const startDecorator = useMemo(
    () =>
      allowAttachmentsUpload ? (
        <AttachmentsUploader disabled={disabled} referenceId={REFERENCE_ID} uppy={uppy} />
      ) : undefined,
    [allowAttachmentsUpload, disabled, uppy],
  );

  const endDecorator = useMemo(() => {
    return (
      <IconButton
        Icon={isLoading ? SvgLoading02 : CornerDownLeft}
        color="neutral"
        disabled={disabled || !input}
        iconClassName={isLoading ? 'animate-spin' : ''}
        onClick={onInputSubmit}
        size="md"
        variant="outline"
      />
    );
  }, [disabled, input, isLoading, onInputSubmit]);

  const slotProps = useMemo(
    () => ({
      root: {
        attachments,
        isUploading,
        onRemoveAttachment,
      },
      ...chatInputSlotProps,
      ...(_slotProps ?? {}),
    }),
    [_slotProps, attachments, onRemoveAttachment, isUploading],
  );

  return (
    <Textarea
      // eslint-disable-next-line jsx-a11y/no-autofocus -- needed for focussing on input
      autoFocus
      className={clsx('gap-xs p-md flex min-h-14 items-center shadow-md', className)}
      disabled={disabled}
      endDecorator={endDecorator}
      onChange={onInputChange}
      onKeyDown={onKeyDown}
      placeholder={placeholder}
      slotProps={slotProps}
      slots={SLOTS}
      startDecorator={startDecorator}
      value={input}
      {...restProps}
    />
  );
}

function MessageInputWrapper(props: MessageInputProps) {
  return (
    <UppyProvider>
      <MessageInput {...props} />
    </UppyProvider>
  );
}

export { MessageInputWrapper as MessageInput };
