import type { ReactElement } from 'react';
import type { BlockComponentStateId, BlockComponentType } from '../../types/block';
import type { StyleObject } from '../../types/styleObject';
import type { SlotType } from '../../types/slot';
import type { Appearance as TypographyAppearance } from '../Typography/types';
import type { ChartColours, ChartTypes } from '../Chart/types';
import type { FormAppearance } from '../Form/types';
import type { ButtonComponentType } from '../Button/types';
import type { StackComponentType } from '../Stack/types';
import type { FileType } from '../FileUpload/types';
import type { Appearance as MediaBlockAppearance } from '../Media/types';
import type { Event } from '../../types/event';

export type TableData = {
  rowData: Record<string, string | number>[];
  columns: { type: string; label: string; id?: string; addOns?: Record<string, unknown> }[];
};

export type StatCardData = {
  type: 'NUMBER' | 'CURRENCY' | 'PERCENT' | 'TIME' | 'STRING';
  label: string;
  description?: string;
  value: string;
  thresholdValue?: number;
  currencyCode?: string;
  notation?: 'standard' | 'scientific' | 'engineering' | 'compact' | 'text';
  trend?: {
    value: string;
    type: 'CURRENCY' | 'PERCENT' | 'NUMBER';
    decimalPlaces: number;
    signDisplay: 'POSITIVE_AND_NEGATIVE';
    notation?: 'standard' | 'scientific' | 'engineering' | 'compact';
    style?: 'decimal' | 'unit';
    unit?: string;
    currencyCode?: string;
  };
};

export type TypographyData = { type?: 'PLAIN_TEXT' | 'MARKDOWN'; text: string };

export type WorkflowInputFormData = { value: Record<string, unknown> };

export type KeyValueData = {
  fields: {
    id?: string;
    type:
      | 'STRING'
      | 'DATE'
      | 'TIME'
      | 'CURRENCY'
      | 'LINK'
      | 'DATETIME'
      | 'PERCENT'
      | 'NUMBER'
      | 'BUTTON';
    value: string;
    label: string;
    description?: string;
    currencyCode?: string;
    dateFormat?: string;
    precision?: 'years' | 'months' | 'days' | 'hours' | 'minutes' | 'seconds' | 'milliseconds';
    notation?: 'compact' | 'comfortable';
    maxSignificantUnits?: string;
    displayText?: string;
    decimalPlaces?: string;
    unit?: string;
    style?: 'decimal' | 'unit';
    blockId?: string;
  }[];
};

export type TypographyBlockParams = {
  blockType: 'Typography';
  data: TypographyData;
  appearance?: TypographyAppearance;
};

export type KeyValueBlockParams = {
  blockType: 'KeyValue';
  data: KeyValueData;
};

export type StatCardBlockParams = {
  blockType: 'StatCard';
  data: StatCardData;
};

export type TableBlockParams = {
  blockType: 'Table';
  data: TableData;
};

export type ChartBlockData = {
  chartType: ChartTypes;
  xAxisValue: string;
  groupBy?: string;
  series?: { key: string; label?: string }[];
  xAxis?: {
    label?: string;
    scale: 'time' | 'linear' | 'log';
    tickLabels?: {
      enabled: boolean;
      decimalPlaces?: number;
      format?: string;
      notation?: 'compact' | 'standard';
    };
  };
  yAxis?: {
    series: { key: string; label?: string }[];
    label?: string;
    scale: 'time' | 'linear' | 'log';
    tickLabels?: {
      enabled: boolean;
      decimalPlaces?: number;
      format?: string;
      notation?: 'compact' | 'standard';
    };
  };
  yAxisRight?: {
    series: { key: string; label?: string }[];
    label?: string;
    scale: 'time' | 'linear' | 'log';
    tickLabels?: {
      enabled: boolean;
      decimalPlaces?: number;
      format?: string;
      notation?: 'compact' | 'standard';
    };
  };
  chartData: Record<string, string | number>[];
  showLegends?: boolean;
  showTotal?: boolean;
  visibleDataPoints?: number;
};

export type ChartBlockAppearance = {
  chartColours?: ChartColours;
};

export type ChartBlockParams = {
  blockType: 'Chart';
  data: ChartBlockData;
  appearance?: ChartBlockAppearance;
};

export type MediaBlockParams = {
  blockType: 'Media';
  data: (FileType & Pick<MediaBlockAppearance, 'showCaption'>)[];
};

export type CitationBlockParams = {
  blockType: 'Citation';
  data: {
    citations: {
      referenceUrl?: string;
      knowledgeSourceType?: string;
      title?: string;
      appName?: string;
      //todo: adding temporarily till BE changes are merged
      name?: string;
    }[];
  };
};

export type StackBlockParams = {
  blockType: StackComponentType['componentType'];
  appearance: StackComponentType['appearance'];
  data: Record<string, BlockMessageContentType>;
};

export type ButtonBlockParams = {
  blockType: ButtonComponentType['componentType'];
  appearance: ButtonComponentType['appearance'];
  content: ButtonComponentType['content'];
  events?: Event[];
};

export type WorkflowInputFormBlockParams = {
  blockType: 'Form';
  data: WorkflowInputFormData;
  appearance?: FormAppearance;
};

export type BlockMessageContentType =
  | TypographyBlockParams
  | KeyValueBlockParams
  | StatCardBlockParams
  | TableBlockParams
  | ChartBlockParams
  | WorkflowInputFormBlockParams
  | CitationBlockParams
  | ButtonBlockParams
  | StackBlockParams
  | MediaBlockParams;

export const enum MessageType {
  User = 'Fan',
  Bot = 'Bot',
}

export type PaginatedMessages = { response: { messages?: Message[] } };

export enum FeedbackReaction {
  LIKE = 'like',
  DISLIKE = 'dislike',
}

export type Feedback = {
  reaction?: FeedbackReaction;
  feedbackCategories?: string[];
  userFeedback?: string;
};

export type Message = {
  messageId: string;
  caseId?: string;
  createdTime: number;
  messageType: MessageType;
  message: BlockMessageContentType[];
  feedback?: Feedback;
  attachments?: FileType[];
  interfaceDetails?: {
    interfaceId: string;
    pageId: string;
    slug: string;
    pageInputs?: Record<string, unknown>;
  };
};

export type PrimaryActionItem = {
  id: string;
  onClick: (e: React.MouseEvent<HTMLButtonElement>, message: BlockMessageContentType[]) => void;
  variant?: 'solid' | 'outline' | 'soft' | 'ghost';
  color?: 'brand' | 'neutral' | 'danger';
  label: string;
};

type Content = {
  chatId?: string;
  secondaryUserName: string; // name to be show for replies
  placeholder: string;
  exampleMessages?: string[];
  automationsConfig?: unknown;
  initialPrompt?: string;
  size: 'sm' | 'lg';
  primaryActionItems?: PrimaryActionItem[];
  onRegenerateResponse?: (messageId: string) => void;
  onCopyMessage?: () => void;
  onLikeMessage?: () => void;
  onDislikeMessage?: () => void;
  ChatHeader?: (props: {
    isScrolled?: boolean;
    onClose?: () => void;
    onClearConversation?: () => void;
  }) => ReactElement;
  averageResponseTime?: number;
  welcomeText?: string;
};

type Appearance = {
  showTimeStamp?: boolean;
  styles?: Pick<StyleObject, 'padding' | 'backgroundColor'>;
  welcomeText?: string;
};

type Slots = {
  header?: SlotType;
};

export type ChatComponentType = BlockComponentType<'Chat', Content, Appearance, Slots>;

export type ChatComponentState = Pick<
  ChatComponentType,
  'content' | 'appearance' | 'componentType'
> &
  BlockComponentStateId;
