'use client';
import type { TypographyColors } from '@unifyapps/defs/types/typography';
import { TypographyWithTooltip as BaseTypographyWithTooltip } from '@unifyapps/carbon/components/TypographyWithTooltip';
import { Typography as BaseTypography } from '@unifyapps/ui/components/Typography';
import type { BlockComponentProps } from '@unifyapps/carbon/no-code/components/BlockRenderer/types';
import type {
  TypographyBlockStateType,
  TypographyComponentType,
} from '@unifyapps/defs/blocks/Typography/types';
import { useBlockAppearanceStyles } from '@unifyapps/carbon/no-code/hooks/useBlockAppearanceStyles';
import { clsx } from 'clsx';
import React, { lazy, useMemo, memo } from 'react';
import _isNil from 'lodash/isNil';
import { getConditionalPropertyValue } from '@unifyapps/carbon/utils/conditionalValues';
import { MARKDOWN_CLASSNAMES } from './const';

const Markdown = lazy(
  () =>
    import(
      /* webpackChunkName: "block-markdown" */
      './Markdown'
    ),
);

const HtmlContent = lazy(
  () =>
    import(
      /* webpackChunkName: "block-html" */
      '@unifyapps/carbon/components/HtmlContent'
    ),
);

const NON_BREAKING_SPACE = ' ';

function TypographyBlock({
  dataAttributes,
  computedBlockState,
}: BlockComponentProps<
  TypographyComponentType,
  TypographyBlockStateType,
  TypographyBlockStateType
>) {
  const {
    content: { value, type },
    appearance,
  } = computedBlockState;

  const {
    weight,
    variant,
    styles,
    maxNumberOfLines,
    minNumberOfLines,
    align,
    color: _color,
    showTooltipOnTextOverflow,
    tooltipPlacement = 'top',
    preserveNewline = true,
  } = appearance ?? {};

  const color = useMemo(
    () => getConditionalPropertyValue<TypographyColors>({ value: _color }),
    [_color],
  );

  const { className, style } = useBlockAppearanceStyles({
    appearanceStyles: styles,
  });

  const typographyProps = {
    align,
    maxNumberOfLines,
    minNumberOfLines,
    style,
    variant,
    weight,
    ...dataAttributes,
  };

  const Typography = showTooltipOnTextOverflow ? BaseTypographyWithTooltip : BaseTypography;

  if (type === 'MARKDOWN' && !_isNil(value)) {
    return (
      <Typography
        // temporary fix for prose styles, need to be mapped correctly
        className={clsx(MARKDOWN_CLASSNAMES, className, color)}
        {...typographyProps}
      >
        <React.Suspense fallback={null}>
          <Markdown>{`${value}`}</Markdown>
        </React.Suspense>
      </Typography>
    );
  }

  if (type === 'HTML' && !_isNil(value)) {
    return (
      <HtmlContent
        className={clsx(className, color)}
        {...typographyProps}
        autofocus={false}
        value={value}
      />
    );
  }

  return (
    <Typography
      // Added whitespace-pre-line so that the new lines are preserved, earlier this was added in Typography component in this PR - https://github.com/unify-apps/www/pull/3276
      // But was reverted due to some issues, adding this class in Typography block based on preserveNewline flag
      className={clsx(className, color, { 'whitespace-pre-line': preserveNewline })}
      {...typographyProps}
      placement={tooltipPlacement}
    >
      {!_isNil(value) ? `${value}` : NON_BREAKING_SPACE}
    </Typography>
  );
}

export default memo(TypographyBlock);
