import type { CSSProperties, ReactElement } from 'react';
import type { FileType } from '@unifyapps/defs/blocks/FileUpload/types';
import { Box } from '@unifyapps/ui/components/Box';
import { clsx } from 'clsx';
import isEmpty from 'lodash/isEmpty';
import Stack from '@unifyapps/ui/_components/Stack';
import './style.css';
import { Loader } from '@unifyapps/ui/components/Loader';
import Media from '../Media';
import { type MediaItemProps, MediaVariants } from '../Media/types';
import { SpaciousMediaItem } from '../Media/components/SpaciousMediaItem';

function ImageItem({ item, onRemoveItem, onClick, overrides }: MediaItemProps) {
  return (
    <SpaciousMediaItem
      className="!h-14 !w-14 flex-none"
      item={item}
      onClick={onClick}
      onRemoveItem={onRemoveItem}
      overrides={overrides}
      showCaption={false}
      size="md"
    />
  );
}

const OVERRIDES = { ImageItem };

export function TextareaRoot({
  children,
  className,
  attachments,
  style,
  isUploading,
  onRemoveAttachment,
  ...rest
}: {
  children: ReactElement;
  className: string;
  style: CSSProperties;
  attachments?: FileType[];
  isUploading: boolean;
  onRemoveAttachment?: (id: string) => void;
}) {
  return (
    <Box className={clsx('bg-primary !gap-lg flex flex-col', className)} style={style} {...rest}>
      {!attachments || isEmpty(attachments) ? null : (
        <Stack className="gap-lg w-full items-center overflow-x-auto" direction="row" gap="2">
          {isUploading ? (
            <Loader size="xs" variant="circle" />
          ) : (
            <Media
              className="!flex flex-row flex-nowrap overflow-x-auto pb-3 pt-2"
              cols={4}
              itemClassName="!max-w-[200px]"
              maxUpfrontItems={100}
              media={attachments}
              onRemoveItem={onRemoveAttachment}
              overrides={OVERRIDES}
              variant={MediaVariants.Compact}
            />
          )}
        </Stack>
      )}
      <Stack className="gap-md default-placeholder w-full flex-1 items-center" direction="row">
        {children}
      </Stack>
    </Box>
  );
}
