import React, { createContext, useCallback, useContext, useMemo } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import type { Feedback, Message, PrimaryActionItem } from '@unifyapps/defs/blocks/Chat/types';
import { MessageType } from '@unifyapps/defs/blocks/Chat/types';
import { clsx } from 'clsx';
import { useRegistryContext } from '@unifyapps/carbon/no-code/components/RegistryProvider';
import { Box } from '@unifyapps/ui/components/Box';
import { InterfaceProvider } from '@unifyapps/carbon/no-code/components/InterfaceProvider';
import { InterfaceClient } from '@unifyapps/carbon/no-code/stores/InterfaceStore';
import { InterfacePage } from '@unifyapps/carbon/no-code/components/InterfacePage';
import { InterfacePageEntityComponentType } from '@unifyapps/defs/types/page';
import Stack from '@unifyapps/ui/_components/Stack/Stack';
import { Tooltip } from '@unifyapps/ui/components/Tooltip';
import { Avatar } from '@unifyapps/ui/components/Avatar';
import type { FileType } from '@unifyapps/defs/blocks/FileUpload/types';
import useEventCallback from '@unifyapps/hooks/useEventCallback';
import { cva } from 'class-variance-authority';
import UnifyAi from '@unifyapps/icons/colored/UnifyAi';
import type { AutomationConfig } from '@unifyapps/defs/blocks/Copilot/types';
import { getAttachmentsBlockId } from '../../helpers/generateMessage';
import { useChatContext } from '../ChatContext';
import { FallbackComponent } from './FallbackMessage';
import { useMessageFeedback } from './hooks/useMessageFeedback';
import { FeedbackModal } from './components/FeedbackModal';
import { MessageFooter } from './MessageFooter';
import { useSecondaryActionItems } from './hooks/useSecondaryActionItems';

const fallbackComponent = <FallbackComponent />;

type ChatMessageProps = {
  message: Message;
  isLastMessage?: boolean;
  userName?: string;
  primaryActionItems?: PrimaryActionItem[];
  onRegenerateResponse?: (messageId: string) => void;
  onCopyMessage?: () => void;
  size?: 'sm' | 'lg';
  secondaryUserName?: string;
  refetchMessage: (messageId: string) => void;
  onSubmitMessage?: (
    input: { message: string; attachments?: FileType[] },
    messageId?: string,
  ) => void;
  lastMessageType?: MessageType;
  automationsConfig?: AutomationConfig;
};

const ChatMessageContext = createContext<ChatMessageProps | undefined>(undefined);

const useChatMessageContext = () => {
  const context = useContext(ChatMessageContext);
  if (!context) {
    throw new Error('useChatMessageContext must be used within a ChatMessageProvider');
  }
  return context;
};

const paddingTopClassName = cva('', {
  variants: {
    size: {
      sm: 'pt-2xl',
      lg: 'pt-5xl',
    },
    hasSameMessageType: {
      true: '',
      false: '',
    },
  },
  compoundVariants: [
    { size: 'sm', hasSameMessageType: true, className: 'pt-xl' },
    { size: 'sm', hasSameMessageType: false, className: 'pt-2xl' },
    { size: 'lg', hasSameMessageType: true, className: 'pt-xl' },
    { size: 'lg', hasSameMessageType: false, className: 'pt-5xl' },
  ],
});

ChatMessage.User = function UserChatMessage() {
  const { message, size, lastMessageType } = useChatMessageContext();
  if (message.messageType !== MessageType.User) {
    return null;
  }
  const hasSameMessageType = lastMessageType === MessageType.User;
  const rootClassName = paddingTopClassName({ size, hasSameMessageType });

  if (size === 'sm') {
    return (
      <Stack className={clsx('gap-sm ps-4xl w-full', rootClassName)}>
        <Stack className="bg-utility-brand-50 py-xl px-2xl rounded-4xl gap-lg w-fit min-w-[240px] self-end">
          <ChatMessage.Blocks />
        </Stack>
        <ChatMessage.Attachments />
      </Stack>
    );
  }

  return (
    <Stack className={clsx('gap-sm w-full items-end', rootClassName)}>
      <Stack
        className="bg-utility-brand-50 py-lg px-2xl rounded-4xl gap-2xl w-fit min-w-[240px] max-w-[640px] items-center justify-between justify-self-end"
        direction="row"
      >
        <ChatMessage.Blocks />
        <ChatMessage.UserAvatar />
      </Stack>
      <ChatMessage.Attachments />
    </Stack>
  );
};

ChatMessage.Brand = function BrandChatMessage() {
  const { message, refetchMessage, size, lastMessageType, automationsConfig } =
    useChatMessageContext();
  const { onMessageClick, activeMessageId } = useChatContext();

  const {
    onSaveFeedback,
    isPending,
    feedbackModalOpen,
    closeFeedbackModal,
    onDislikeMessage,
    onLikeMessage,
  } = useMessageFeedback({
    message,
    refetchMessage,
    automationsConfig,
  });

  const onClick = useCallback(() => onMessageClick?.(message), [message, onMessageClick]);
  const hasSameMessageType = lastMessageType === MessageType.Bot;
  const rootClassName = paddingTopClassName({ size, hasSameMessageType });

  if (message.messageType === MessageType.User) {
    return null;
  }

  if (size === 'sm') {
    return (
      <Box className={clsx('flex w-full', rootClassName)}>
        <Stack
          className={clsx('gap-md w-full', {
            'p-sm': Boolean(onMessageClick),
            'rounded-4xl bg-primary_hover shadow-[inset_0_0_0_2px_var(--border-brand-solid)]':
              message.messageId === activeMessageId,
          })}
          onClick={onClick}
        >
          <ChatMessage.BrandIcon />
          <ChatMessage.Blocks />
          <ChatMessage.InterfacePage />
          <ChatMessage.Attachments />
        </Stack>
      </Box>
    );
  }

  return (
    <Box className={clsx('flex w-full', rootClassName)}>
      <Stack
        className="gap-md px-xl py-lg hover:bg-primary_hover rounded-4xl group relative w-full cursor-pointer"
        direction="row"
        onClick={onClick}
      >
        <ChatMessage.BrandIcon className="mt-xs" />
        <Stack className="flex-1">
          <ChatMessage.Blocks />
          <ChatMessage.InterfacePage />
          <ChatMessage.Attachments />
        </Stack>
        <ChatMessage.Footer onDislikeMessage={onDislikeMessage} onLikeMessage={onLikeMessage} />
      </Stack>
      <ChatMessage.FeedBack
        closeFeedbackModal={closeFeedbackModal}
        feedbackModalOpen={feedbackModalOpen}
        isPending={isPending}
        onSaveFeedback={onSaveFeedback}
      />
    </Box>
  );
};

ChatMessage.Blocks = function ChatMessageBlocks() {
  const { message } = useChatMessageContext();
  const { registry } = useRegistryContext();
  const BlockRenderer = registry.getBlockRenderer();

  return <BlockRenderer blockId={message.messageId} key={message.messageId} />;
};

ChatMessage.Attachments = function ChatMessageAttachments() {
  const { message } = useChatMessageContext();
  const { registry } = useRegistryContext();
  const BlockRenderer = registry.getBlockRenderer();

  if (!message.attachments?.length) {
    return null;
  }

  return (
    <Box className="flex w-full justify-end">
      <BlockRenderer
        blockId={getAttachmentsBlockId(message.messageId)}
        key={getAttachmentsBlockId(message.messageId)}
      />
    </Box>
  );
};

ChatMessage.FeedBack = function ChatMessageFeedBack({
  onSaveFeedback,
  isPending,
  feedbackModalOpen,
  closeFeedbackModal,
  feedback,
}: {
  feedback?: Feedback;
  onSaveFeedback: (feedback: Feedback, onSuccess?: () => void) => void;
  isPending: boolean;
  feedbackModalOpen: boolean;
  closeFeedbackModal: () => void;
}) {
  return feedbackModalOpen ? (
    <FeedbackModal
      feedback={feedback}
      isPending={isPending}
      onClose={closeFeedbackModal}
      onSaveFeedback={onSaveFeedback}
    />
  ) : null;
};

ChatMessage.InterfacePage = function ChatMessageInterfacePage() {
  const { message, onSubmitMessage } = useChatMessageContext();
  const { registry } = useRegistryContext();

  const interfaceComponent = useMemo(
    () =>
      message.interfaceDetails?.pageId
        ? {
            slug: message.interfaceDetails.slug,
            type: InterfacePageEntityComponentType.PAGE,
            id: message.interfaceDetails.pageId,
          }
        : null,
    [message.interfaceDetails?.pageId, message.interfaceDetails?.slug],
  );

  const emitPageEvent = useEventCallback(
    ({
      eventPayload,
    }: {
      eventPayload: {
        message: string;
        attachments?: FileType[];
        messageId?: string;
      };
    }) => {
      onSubmitMessage?.(
        { message: eventPayload.message, attachments: eventPayload.attachments },
        message.messageId,
      );

      return Promise.resolve();
    },
  ) as (props: { eventPayload?: unknown }) => Promise<void>;

  return message.interfaceDetails?.interfaceId && interfaceComponent ? (
    <InterfaceProvider
      // mode should be runner so that page actions works, and client should be UA_PLATFORM so interface permissions call doesn't go
      client={InterfaceClient.UA_PLATFORM}
      emitPageEvent={emitPageEvent}
      interfaceComponent={interfaceComponent}
      interfaceSlug={message.interfaceDetails.interfaceId}
      pageInputs={message.interfaceDetails.pageInputs}
      registry={registry}
    >
      <InterfacePage />
    </InterfaceProvider>
  ) : null;
};

ChatMessage.UserAvatar = function ChatMessageUserAvatar() {
  const { userName } = useChatMessageContext();

  return (
    <Box className="flex h-full">
      <Tooltip placement="bottom" title={userName}>
        <Avatar
          //todo: remove important post DS update
          className="!bg-primary"
          color="brand_subtle"
          name={userName}
          size="sm"
        />
      </Tooltip>
    </Box>
  );
};

ChatMessage.Footer = function ChatMessageFooter({
  onDislikeMessage,
  onLikeMessage,
}: {
  onDislikeMessage?: () => void;
  onLikeMessage?: () => void;
}) {
  const {
    primaryActionItems,
    message,
    onRegenerateResponse,
    isLastMessage,
    onCopyMessage,
    automationsConfig,
  } = useChatMessageContext();
  const { readonly } = useChatContext();

  const onRetry = useCallback(
    () => onRegenerateResponse?.(message.messageId),
    [message.messageId, onRegenerateResponse],
  );

  const secondaryActionItems = useSecondaryActionItems({
    onCopyMessage,
    onDislikeMessage,
    onLikeMessage,
    onRegenerateResponse: onRegenerateResponse ? onRetry : undefined,
    isLastMessage,
    message,
    automationsConfig,
  });

  if (readonly) {
    return null;
  }

  return (
    <MessageFooter
      className="gap-xs absolute -bottom-7 start-11 hidden h-7 items-center pt-2 group-hover:flex"
      message={message.message}
      primaryActionItems={primaryActionItems}
      secondaryActionItems={secondaryActionItems}
    />
  );
};

ChatMessage.BrandIcon = function ChatMessageBrandIcon({ className }: { className?: string }) {
  const { message, lastMessageType } = useChatMessageContext();
  if (message.messageType === lastMessageType) {
    return null;
  }

  return (
    <Box className={clsx('size-6', className)}>
      <UnifyAi />
    </Box>
  );
};

function ChatMessage(props: ChatMessageProps) {
  return (
    <ChatMessageContext.Provider value={props}>
      <ErrorBoundary fallback={fallbackComponent}>
        <ChatMessage.User />
        <ChatMessage.Brand />
      </ErrorBoundary>
    </ChatMessageContext.Provider>
  );
}

export default ChatMessage;
