import Stack from '@unifyapps/ui/_components/Stack';
import { useTranslation } from '@unifyapps/i18n/client';
import { Pill } from '@unifyapps/ui/components/Pill';
import { Button } from '@unifyapps/ui/components/Button';
import SvgChevronRight from '@unifyapps/icons/outline/ChevronRight';
import { useMemo } from 'react';
import type { CitationType } from '@unifyapps/defs/blocks/Citation/types';
import { getCitationTitle } from '../helpers/getCitationTitle';
import { useCitationsPanelContext } from './CitationsPanelProvider';
import { CitationIcon } from './CitationIcon';

type Props = {
  citations: CitationType[];
};

const openCitationUrl = (citation: CitationType) => {
  window.open(citation.referenceUrl, '_blank');
};

export function UpfrontCitationSources({ citations }: Props) {
  const { t } = useTranslation(['copilot']);
  const { showPanel } = useCitationsPanelContext();

  const uniqueCitationSources = useMemo(() => {
    // We only want to show unique citation titles upfront.
    // Eg. If there are multiple sources from same slack channel, we only want to show that slack channel once upfront.
    //     In panel, we can show all sources.
    const citationsSet = new Set();
    return citations.reduce<CitationType[]>((acc, item) => {
      const title = getCitationTitle(item);
      if (!citationsSet.has(title)) {
        acc.push(item);
        citationsSet.add(title);
      }
      return acc;
    }, []);
  }, [citations]);

  return (
    <Stack className="gap-sm flex-wrap items-center self-start" direction="row">
      {uniqueCitationSources.map((citation, index) => {
        return (
          <Pill
            key={index}
            label={getCitationTitle(citation)}
            labelClassName="max-w-[200px] !break-all"
            onClick={() => openCitationUrl(citation)}
            pillClassName="!gap-xs"
            startDecoratorNode={
              <CitationIcon citation={citation} className="size-3 flex-shrink-0" />
            }
          />
        );
      })}
      <Button
        className="text-xs"
        color="neutral"
        endDecoratorComponent={SvgChevronRight}
        onClick={() => {
          showPanel(citations);
        }}
        size="sm"
        variant="soft"
      >
        {t('ai-agents:ViewDetails')}
      </Button>
    </Stack>
  );
}
