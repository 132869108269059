import { useEffect, useState } from 'react';
import { useTranslation } from '@unifyapps/i18n/client';
import Stack from '@unifyapps/ui/_components/Stack';
import { Typography } from '@unifyapps/ui/components/Typography';
import { cva } from 'class-variance-authority';
import { Box } from '@unifyapps/ui/components/Box';
import UnifyAiLoader from './UnifyAiLoader';

const INITIAL_LOADER_DELAY = 4000;

const loaderContainerSizeVariants = cva('gap-md items-center', {
  variants: {
    size: {
      sm: 'pb-md',
      lg: 'relative ps-2xl pb-xl',
    },
  },
});

const loaderSizeVariants = cva('size-6', {
  variants: {
    size: {
      sm: '',
      lg: 'absolute top-xs -start-6',
    },
  },
});

export function Loader({
  averageResponseTime,
  size,
}: {
  averageResponseTime: number;
  size?: 'sm' | 'lg';
}) {
  const { t } = useTranslation('copilot');
  const [loader, setLoader] = useState<string>('');

  useEffect(() => {
    let timer2: NodeJS.Timeout, timer3: NodeJS.Timeout, timer4: NodeJS.Timeout;

    const DELAY = averageResponseTime / 4; // number of loading states here is 4

    const timer1 = setTimeout(() => {
      setLoader(t('Loader.AnalyzingMessage'));

      timer2 = setTimeout(() => {
        setLoader(t('Loader.EvaluatingMessage'));

        timer3 = setTimeout(() => {
          setLoader(t('Loader.ProcessingMessage'));

          timer4 = setTimeout(() => {
            setLoader(t('Loader.ResponseMessage'));
          }, DELAY);
        }, DELAY);
      }, DELAY);
    }, INITIAL_LOADER_DELAY);

    return () => {
      clearTimeout(timer1);
      clearTimeout(timer2);
      clearTimeout(timer3);
      clearTimeout(timer4);
    };
  }, [averageResponseTime, t]);

  return (
    <Stack className={size === 'sm' ? 'mt-2xl' : 'mt-5xl'}>
      <Stack className={loaderContainerSizeVariants({ size })} direction="row" key={loader}>
        <Box className={loaderSizeVariants({ size })}>
          <UnifyAiLoader />
        </Box>
        <Typography className="prose" color="text-placeholder" variant="text-md" weight="medium">
          {loader}
        </Typography>
      </Stack>
    </Stack>
  );
}
