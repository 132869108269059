import type { BlockTypeUnionType, BlockType, BlockId } from '@unifyapps/defs/types/block';
import keyBy from 'lodash/keyBy';
import type { InterfacePageEntity } from '@unifyapps/defs/types/page';
import type { DeviceVariantType } from '@unifyapps/defs/types/deviceVariant';
import type { BlockEditorDefinition, BlockStateDefinition } from '../BlockDefinition';
import type { InsertBlockContext } from '../../stores/InterfaceStore/actions/insertBlock';
import type { GlobalStateStoreType } from '../../stores/GlobalStateStore';
import type { BlockRendererComponentType, BlocksRecordType } from './types';

class BlocksRegistry {
  private readonly name: string;
  private readonly blockRenderer: BlockRendererComponentType;
  private readonly blockEditorDefinitions?: readonly BlockEditorDefinition[];
  private readonly blockStateDefinitionsByType?: Record<string, BlockStateDefinition>;
  private readonly blockEditorDefinitionsByType?: Record<string, BlockEditorDefinition>;
  private readonly blocks: Partial<BlocksRecordType>;

  constructor({
    blockRenderer,
    blocks,
    name,
    blockEditorDefinitions,
    blockStateDefinitions,
  }: {
    name: string;
    blockRenderer: BlockRendererComponentType;
    blocks: Partial<BlocksRecordType>;
    blockEditorDefinitions?: readonly BlockEditorDefinition[];
    blockStateDefinitions?: readonly BlockStateDefinition[];
  }) {
    this.name = name;
    this.blockRenderer = blockRenderer;
    this.blocks = blocks;
    this.blockEditorDefinitions = blockEditorDefinitions;
    this.blockStateDefinitionsByType = keyBy(blockStateDefinitions, 'type');
    this.blockEditorDefinitionsByType = keyBy(blockEditorDefinitions, 'type');
  }

  getBlockStateDefinition(name: BlockTypeUnionType) {
    const blockStateDefinition = this.blockStateDefinitionsByType?.[name];
    if (blockStateDefinition) return blockStateDefinition;
    console.error(`Block state definition not found for block type: ${name}`);
  }
  getBlockEditorDefinition(name: BlockTypeUnionType) {
    const blockEditorDefinition = this.blockEditorDefinitionsByType?.[name];
    if (blockEditorDefinition) return blockEditorDefinition;
    console.error(`Block editor definition not found for block type: ${name}`);
  }

  getBlockComponent(name: BlockTypeUnionType) {
    return this.blocks[name] ?? this.getBlockStateDefinition(name)?.getComponent();
  }

  getBlockInitialFormData(
    name: BlockTypeUnionType,
    device?: DeviceVariantType,
    context?: InsertBlockContext,
  ) {
    return this.getBlockEditorDefinition(name)?.getInitialFormData(device, context);
  }

  getBlockInfo(name: BlockTypeUnionType) {
    return this.getBlockEditorDefinition(name)?.info;
  }

  getBlockContentSchema(block: BlockType) {
    const name = block.component.componentType;

    return this.getBlockEditorDefinition(name)?.getContentSchema(block) ?? {};
  }

  getBlockAppearanceSchema(
    block: BlockType,
    params?: { getGlobalStateStoreState: () => GlobalStateStoreType },
  ) {
    const name = block.component.componentType;

    return this.getBlockEditorDefinition(name)?.getAppearanceSchema(block, params) ?? {};
  }

  getSlotOrder(block: BlockType) {
    return this.getBlockEditorDefinition(block.component.componentType)?.getSlotOrder(block);
  }

  getBlockCompositionData(name: BlockTypeUnionType) {
    return this.getBlockEditorDefinition(name)?.getCompositionData();
  }

  getUseBlockRef(name?: BlockTypeUnionType) {
    return name ? this.getBlockStateDefinition(name)?.useBlockRef : false;
  }

  getBlockStateSchema(
    name: BlockTypeUnionType,
    block: BlockType,
    {
      getDataSourceOutputSchema,
      modules,
      activeBlockId,
      isBlockAncestorOfActiveBlock,
    }: {
      getDataSourceOutputSchema: (params: { block: BlockType; data: string }) => object | undefined;
      modules: InterfacePageEntity[] | undefined;
      activeBlockId: BlockId;
      isBlockAncestorOfActiveBlock?: boolean;
    },
  ) {
    return this.getBlockEditorDefinition(name)?.stateSchema(block, {
      getDataSourceOutputSchema,
      modules,
      activeBlockId,
      isBlockAncestorOfActiveBlock,
    });
  }

  getBlockControlMethods(name: BlockTypeUnionType, block: BlockType) {
    return this.getBlockStateDefinition(name)?.getBlockControlMethods(block);
  }

  getBlockRenderer() {
    return this.blockRenderer;
  }

  getBlockInitialState(name: BlockTypeUnionType, block: BlockType) {
    return this.getBlockStateDefinition(name)?.initialStateGetter(block);
  }

  getBlockAddOnSchema(name: BlockTypeUnionType, addOnId: string) {
    return this.getBlockEditorDefinition(name)?.addOnSchemaGetter(addOnId);
  }

  getBlockMethodSchema(block: BlockType, methodName: string) {
    const blockComponent = block.component;
    const blockComponentType = blockComponent.componentType;

    return this.getBlockEditorDefinition(blockComponentType)?.controlMethodSchemaGetter(
      methodName,
      block,
    );
  }

  preloadBlock(name: BlockTypeUnionType) {
    this.getBlockStateDefinition(name)?.preloadBlock?.();
  }

  getEventTargetIds(name: BlockTypeUnionType, block: BlockType) {
    return this.getBlockStateDefinition(name)?.getEventTargetIds(block);
  }

  getRegistryName() {
    return this.name;
  }

  getAllDefinitions() {
    return this.blockEditorDefinitions;
  }

  getAllBlocksIcons() {
    return Object.entries(this.blockEditorDefinitions ?? {}).reduce<Record<string, string>>(
      (acc, [key, value]) => {
        acc[value.type] = value.info.icon;
        return acc;
      },
      {},
    );
  }

  getBlockIcon(name: BlockTypeUnionType) {
    return this.getBlockEditorDefinition(name)?.info.icon;
  }
}

export default BlocksRegistry;
