import { getIconFromRegistry } from '@unifyapps/icons/utils/registry';
import { useLookupSingleKey } from '@unifyapps/network/generated/lookup-rest-api/lookup-rest-api';
import type { NodeBuilder } from '@unifyapps/network/generated/models/nodeBuilder';
import { useMemo } from 'react';
import type { CitationType } from '@unifyapps/defs/blocks/Citation/types';
import { SOURCE_TYPE_VS_ICON } from '../constants';

type Props = {
  citation: CitationType;
  className: string;
};

export function CitationIcon({ citation, className }: Props) {
  const Icon = useMemo(
    () =>
      getIconFromRegistry(
        SOURCE_TYPE_VS_ICON[citation.knowledgeSourceType as keyof typeof SOURCE_TYPE_VS_ICON],
      ),
    [citation.knowledgeSourceType],
  );

  const { data } = useLookupSingleKey<NodeBuilder>(
    {
      lookupType: 'NODE_BUILDER',
      type: 'ByKeys',
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- this query is enabled only if appName is truthy
      keys: [citation.appName!],
      fields: ['displayName', 'iconUrl', 'name'],
    },
    {
      query: {
        enabled: Boolean(citation.appName),
        staleTime: Infinity,
      },
    },
  );

  return Icon ? (
    <Icon className={className} />
  ) : (
    <img alt={data?.displayName} className={className} src={data?.iconUrl} />
  );
}
