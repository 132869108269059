import type { BlockStateDefinition } from '@unifyapps/carbon/no-code/components/BlockDefinition';
import type {
  BlockType,
  BlockStateUnionType,
  ComponentTypeUnionType,
} from '@unifyapps/defs/types/block';
import _pick from 'lodash/pick';
import { lazy } from 'react';

const CategoryFilter = lazy(
  () =>
    import(
      /* webpackChunkName: "category-filter-block" */
      './CategoryFilter'
    ),
);

class CategoryFilterBlockStateDefinition implements BlockStateDefinition {
  get type(): ComponentTypeUnionType {
    return 'CategoryFilter';
  }

  getEventTargetIds() {
    return [];
  }

  getBlockControlMethods() {
    return [
      {
        id: 'clearFilters' as const,
        label: 'Clear Filters',
        method: (blockState: BlockStateUnionType) => {
          return {
            ...blockState,
            filter: undefined,
          };
        },
      },
    ];
  }

  get initialStateGetter() {
    return (block: BlockType) =>
      ({
        id: block.id,
        ..._pick(block.component, ['content', 'appearance', 'componentType']),
      }) as BlockStateUnionType;
  }

  getComponent() {
    return CategoryFilter;
  }
}

export default CategoryFilterBlockStateDefinition;
