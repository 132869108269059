import type { getLookupQueryOptions } from '@unifyapps/network/generated/lookup-rest-api/lookup-rest-api';
import { useLookup } from '@unifyapps/network/generated/lookup-rest-api/lookup-rest-api';
import type { LookupRequest } from '@unifyapps/network/generated/models/lookupRequest';
import type { AutomationConfig } from '@unifyapps/defs/blocks/Copilot/types';

export enum ConfigType {
  Platform = 'PLATFORM',
  AutomationChat = 'AUTOMATION_CHAT',
  AIAgentTest = 'AI_AGENT_TEST',
  AIAgentPromptTest = 'AI_AGENT_TEST_PROMPT',
  CodeGenerator = 'CODE_GENERATOR',
  ModelComparison = 'MODEL_COMPARE',
}

export default function useFetchAIConfig(
  configType: ConfigType,
  options?: Partial<Omit<ReturnType<typeof getLookupQueryOptions>, 'select'>>,
) {
  const {
    data: copilotConfig,
    isLoading,
    error,
  } = useLookup<Awaited<{ response?: { objects?: { properties: AutomationConfig }[] } }>>(
    {
      type: 'ByQuery',
      lookupType: 'ENTITY',
      options: { entity_type: 'co_pilot_config' },
      filter: { op: 'EQUAL', field: 'properties_type', values: [configType] },
    } as LookupRequest,
    { query: { staleTime: Infinity, ...options } },
  );
  return { config: copilotConfig?.response?.objects?.[0]?.properties, isLoading, error };
}
