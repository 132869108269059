import type { BlockStateDefinition } from '@unifyapps/carbon/no-code/components/BlockDefinition';
import type {
  ComponentTypeUnionType,
  BlockType,
  BlockStateUnionType,
} from '@unifyapps/defs/types/block';
import _pick from 'lodash/pick';
import type { Action } from '@unifyapps/defs/types/action';
import { lazy } from 'react';

const Tabs = lazy(
  () =>
    import(
      /* webpackChunkName: "tabs-block" */
      './Tabs'
    ),
);

class TabsBlockStateDefinition implements BlockStateDefinition {
  get type(): ComponentTypeUnionType {
    return 'Tabs';
  }

  getEventTargetIds() {
    return [];
  }

  getBlockControlMethods() {
    return [
      {
        id: 'changeTab' as const,
        label: 'Change Tab',
        method: (blockState: BlockStateUnionType, action: Action) => {
          const { methodPayload } = action.payload as {
            methodPayload: { destinationTabId: string };
          };

          const { destinationTabId } = methodPayload;

          return {
            ...blockState,
            content: {
              ...blockState.content,
              value: destinationTabId,
            },
          } as BlockStateUnionType;
        },
      },
    ];
  }

  get initialStateGetter() {
    return (block: BlockType) =>
      ({
        id: block.id,
        ..._pick(block.component, ['content', 'appearance', 'componentType']),
      }) as BlockStateUnionType;
  }

  getComponent() {
    return Tabs;
  }
}

export default TabsBlockStateDefinition;
