import { Button } from '@unifyapps/ui/components/Button';
import type { ComputedButtonAppearance } from '@unifyapps/defs/types/blocks';
import { clsx } from 'clsx';
import {
  getConditionalIcon,
  getConditionalIconNode,
} from '@unifyapps/carbon/utils/conditionalValues';
import type { SyntheticEvent } from 'react';

type BaseButtonProps = {
  appearance?: ComputedButtonAppearance;
  className?: string;
  loading?: boolean;
  onClick?: (e: SyntheticEvent) => void;
  size?: ComputedButtonAppearance['size'];
  style?: React.CSSProperties;
  type?: 'default' | 'submit' | 'reset';
  dataAttributes?: Record<string, string>;
  value: string;
};
function BaseButton(props: BaseButtonProps) {
  const { appearance, className, loading, onClick, size, style, type, dataAttributes, value } =
    props;

  return (
    <Button
      aria-disabled={appearance?.disabled}
      className={clsx(className, appearance?.className)}
      color={appearance?.color ?? 'brand'}
      disabled={appearance?.disabled}
      endDecoratorComponent={getConditionalIcon(appearance?.endDecorator)}
      endDecoratorNode={getConditionalIconNode(appearance?.endDecorator, size)}
      loading={appearance?.loading || loading}
      onClick={onClick}
      size={size}
      startDecoratorComponent={getConditionalIcon(appearance?.startDecorator)}
      startDecoratorNode={getConditionalIconNode(appearance?.startDecorator, size)}
      style={style}
      type={type === 'default' ? 'button' : type}
      value={value}
      variant={appearance?.variant ?? 'solid'}
      {...dataAttributes}
    >
      {`${value}`}
    </Button>
  );
}

export default BaseButton;
